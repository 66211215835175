import "animate.css";
import { useState, useEffect } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getLangFromUrl, useTranslations } from "../../i18n/utils";
import { FunctionsCard } from "./FunctionsCard";

export const Functions = () => {
  const [sectionShow, setSectionShow] = useState(1);
  const [animateClass, setAnimateClass] = useState("");
  const [pawAnimations, setPawAnimations] = useState({
    paw1: "",
    paw2: "",
  });
  const url = new URL(window.location.href);
  const [scrollY, setScrollY] = useState(0);
  const analytics = getAnalytics();
  const lang = getLangFromUrl(url);
  const t = useTranslations(lang);

  const handleFunctionsClick = (section: string) => {
    logEvent(analytics, "section_click", { section });
  };
  const handleScroll = () => {
    setScrollY(window.scrollY);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`relative w-full lg:mt-10 overflow-hidden py-3 flex flex-col items-center ${animateClass}`}
      id="benefits"
      x-intersect="activeStep = 'spy-3'"
    >
      <div className="w-full flex flex-col-reverse md:flex-row items-center justify-around">
        <img
          src="/img/illustrations/paw.svg"
          alt="paw"
          className={`absolute hidden md:flex left-1/4 lg:left-1/3 top-12 z-10 h-12 w-12 rotate-12 animate__animated ${
            scrollY > 500 ? "animate__fadeInLeft" : "animate__fadeOutLeft"
          }`}
        />
        <img
          src="/img/illustrations/paw.svg"
          alt="paw"
          className={`absolute flex right-10 bottom-0 z-10 h-12 w-12 rotate-12 animate__animated ${
            scrollY > 500 ? "animate__fadeInLeft" : "animate__fadeOutLeft"
          }`}
        />
        <div className="w-full md:w-4/12">
          <img
            src={"/img/illustrations/girl-and-cat.png"}
            alt="Hero image of a pet care app"
            className={`hidden relative w-full max-w-[1000px] md:flex lg:mt-10 animate__animated ${
              scrollY > 500 ? "animate__slideInDown" : "animate__fadeOutLeft"
            } `}
          />
        </div>
        <div
          className={`w-full md:w-7/12 md:max-w-3xl ${
            scrollY > 500 ? "animate__slideInDown" : "animate__fadeOutLeft"
          }`}
        >
          <div className="mb-14">
            <h4 className="font-semibold text-xs tracking-widest uppercase mb-3 text-primary-500">
              {t("functions.family")}
            </h4>
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-medium mb-4 text-muted-800 dark:text-muted-100">
              {t("functions.reasons")}
            </h2>
            <p className="text-base md:text-lg lg:text-xl text-muted-500 dark:text-muted-400">
              {t("functions.description")}
            </p>
          </div>
          <div className="w-full h-10 flex justify-around items-start">
            <p
              onClick={() => {
                setSectionShow(1);
                handleFunctionsClick("Bienestar");
              }}
              className={`cursor-pointer font-medium ${
                sectionShow === 1
                  ? "text-primary-500 border-b-2 border-primary-500"
                  : "text-muted-800 dark:text-muted-300"
              }`}
            >
              {t("functions.wellbeing")}
            </p>
            <p
              onClick={() => {
                setSectionShow(2);
                handleFunctionsClick("Gestión");
              }}
              className={`${
                sectionShow === 2
                  ? "text-primary-500 border-b-2 text-center border-primary-500"
                  : "text-muted-800 dark:text-muted-300"
              } font-medium cursor-pointer`}
            >
              {t("functions.management")}
            </p>
            <p
              onClick={() => {
                setSectionShow(3);
                handleFunctionsClick("Comunicación");
              }}
              className={`${
                sectionShow === 3
                  ? "text-primary-500 border-b-2 text-center border-primary-500"
                  : "text-muted-800 dark:text-muted-300"
              } font-medium cursor-pointer`}
            >
              {t("functions.communication")}
            </p>
          </div>
          <div className="w-full flex flex-col justify-center items-center">
            <FunctionsCard
              description={t("functions.wellbeingDescription")}
              list={t("functions.wellbeingList")}
              showItem={sectionShow}
              number={1}
            />
            <FunctionsCard
              description={t("functions.managementDescription")}
              list={t("functions.managementList")}
              showItem={sectionShow}
              number={2}
            />
            <FunctionsCard
              description={t("functions.communicationDescription")}
              list={t("functions.communicationList")}
              showItem={sectionShow}
              number={3}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
